import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faEnvelope, faSpinner } from '@fortawesome/free-solid-svg-icons';
import { useHistory } from 'react-router-dom';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import * as Yup from 'yup';
import { useMutation } from 'react-query';
import { forgotPasswordApi } from './services/authentication';
import uttarakhanadLogo from '../assets/img/logo/uttarakhanda.png';
import SFALogo from '../assets/img/SFALogo.svg';

const initialValues = {
  username: '',
};

const validationSchema = Yup.object({
  username: Yup.string().email('Invalid email').required('Please enter the email.'),
});

export default function SignIn() {
  const history = useHistory();
  // fetch user profile details
  const [showSuccessMsg, setShowSuccessMsg] = useState(false);
  const [showErrorMsg, setShowErrorMsg] = useState(false);
  const forgotPasswordMutation = useMutation((data) => forgotPasswordApi(data), {
    onSuccess: (result) => {
      if (result.message === 'not found') {
        setShowErrorMsg(true);
        setTimeout(() => {
          setShowErrorMsg(false);
        }, 10000);
      } else {
        setShowSuccessMsg(true);
        setTimeout(() => {
          setShowSuccessMsg(false);
        }, 10000);
      }
    },
    onError: async (err) => {
      console.debug('Error - ', err);
    },
  });

  const handleForgotPassword = () =>
    async function (values, { setSubmitting }) {
      console.log(values);
      setSubmitting(true);
      await forgotPasswordMutation.mutateAsync(values.username);
      setSubmitting(false);
    };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleForgotPassword()}
    >
      {({ isSubmitting }) => (
        <div className="flex flex-row w-screen h-screen">
          <div
            id="blue-bg"
            className="hidden h-screen p-15 lg:flex lg:w-1/2 flex justify-center items-center flex-col text-left font-roboto font-bold"
          >
            <div className="">
              <img src={uttarakhanadLogo} className="h-96" alt={'sfa logo'} />
            </div>
            {/* <div className="mx-20 py-6 mr-8 mb-0 right-tagline font-bold font-poppins">
            Organize,
            <br />
            Play & <br />
            Track The
            <br />
            Easy Way
          </div> */}
            {/* <div className="middle-line bg-opacity-50 w-16 ml-20 mb-10 mt-0  h-1 bg-white" />
          <p className="px-20 py-0 right-info text-left text-white">
            Organize tournaments, manage teams, score <br />
            matches & keep track of your performance in <br />
            every match you play. It's Free!
          </p> */}
          </div>
          <div id="blue-bg" className="w-full lg:w-1/2 md:bg-white md:py-0">
            <img
              src={SFALogo}
              alt="Sfa Play Mobile Logo"
              className="block md:hidden h-12 p-2"
              onClick={() => {
                history.push('/');
              }}
            />
            <div className="py-8 bg-white px-5 md:px-32 lg:px-20 xl:px-32 h-full flex flex-col md:justify-center text-left">
              <div
                className="hidden lg:block absolute top-4 ml-3 cursor-pointer hover:bg-gray-100 rounded-md p-3 py-2 left-1/2"
                onClick={() => history.push('/signin')}
              >
                <FontAwesomeIcon icon={faArrowLeft} className="mx-2" />
                Login
              </div>
              <div className="action text-xl md:text-3.5xl mb-2 md:mb-0">Forgot Password</div>
              <div className="pb-8 md:pb-10 info">
                Provide email address with registered account.
              </div>
              <Form id="forgot-password-form">
                <label className="font-semibold text-sm text-gray-650 pb-2 md:pb-1 block">
                  Email
                </label>
                <div className="flex flex-wrap w-full relative bg-white items-center border-2 rounded-lg">
                  <div className="flex justify-center items-center pl-3 w-15">
                    <FontAwesomeIcon icon={faEnvelope} className="text-gray-450 " />
                  </div>
                  <Field
                    type="text"
                    name="username"
                    placeholder="someone@domain.com"
                    className="bg-red flex-shrink flex-grow leading-normal w-px flex-1 border-0 p-2 border-grey-400 rounded rounded-l-none px-3 self-center relative font-roboto outline-none"
                  />
                </div>
                <ErrorMessage name="username" component="div" className="flex-1 text-red-400" />
                {showSuccessMsg ? (
                  <div className="mt-3 text-green-700">
                    Reset Password link sent successfully. Please check your email.
                  </div>
                ) : null}
                {showErrorMsg ? (
                  <div className="mt-3 text-red-700">
                    User doesn't exists. Please check your email.
                  </div>
                ) : null}
                <div className="flex center flex-row-reverse md:flex-row 2xl:flex-row justify-center w-full">
                  <button
                    type="submit"
                    className="reg-btn-dark w-full ml-1 rounded-lg my-8 px-10 py-2 text-white"
                    disabled={isSubmitting}
                  >
                    {forgotPasswordMutation.isLoading ? (
                      <FontAwesomeIcon icon={faSpinner} className="text-white animate-spin" />
                    ) : (
                      'Submit'
                    )}
                  </button>
                </div>
              </Form>
            </div>
          </div>
        </div>
      )}
    </Formik>
  );
}
